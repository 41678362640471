<template>
    <div 
        v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'admin-operation', 'admin-bd', 'superadmin-tech', 'superadmin-product', 'user-management'])" 
        class="uk-container uk-container-expand uk-margin-top card-scrollable"
    >
        <div class="uk-flex uk-flex-between uk-flex-middle">
            <form @submit.prevent="searchSilverPackage" class="uk-search uk-search-default uk-background-default">
                <span class="uk-search-icon-flip" uk-search-icon></span>
                <input 
                    class="uk-search-input" 
                    type="search" 
                    placeholder="Search vacancy title"
                    v-model="meta.title"
                >
            </form>
            <router-link 
                v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'superadmin-tech', 'superadmin-product'])"
                to="/admin/marketing/silver_package/create" 
                class="uk-button uk-button-primary uk-border-rounded"
            >Create</router-link>
        </div>

        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto uk-margin-top">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-nowrap">Vacancy's Title</th>
                            <th class="uk-text-nowrap">Company</th>
                            <th class="uk-text-nowrap">Start Date</th>
                            <th class="uk-text-nowrap">End Date</th>
                            <th class="uk-text-nowrap">Job Type</th>
                            <th class="uk-text-nowrap">Location</th>
                            <th class="uk-text-nowrap">Fee</th>
                            <th class="uk-text-nowrap">Send to</th>
                            <th class="uk-text-nowrap">Status</th>
                            <th class="uk-text-nowrap">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="10"/>
                    <template v-else>
                        <tbody v-if="silverPackageList !== null && silverPackageList.docs.length > 0">
                            <tr
                                v-for="(item, index) in silverPackageList.docs"
                                :key="index"
                            >
                                <td class="uk-text-nowrap">{{ item.title || '-' }}</td>
                                <td class="uk-text-nowrap">{{ item.company.name || '-' }}</td>
                                <td class="uk-text-nowrap">{{ dateFormat(item.job_start) || '-' }}</td>
                                <td class="uk-text-nowrap">{{ dateFormat(item.job_end) || '-'}}</td>
                                <td class="uk-text-nowrap">{{ item.job_type.name || '-' }}</td>
                                <td class="uk-text-nowrap">{{ item.city || '-' }}</td>
                                <td class="uk-text-nowrap">{{ salaryFormat(item) || '-' }}</td>
                                <td class="uk-text-nowrap">{{ item.company.email || '-' }}</td>
                                <td class="uk-text-nowrap"><label-status :type="'job'" :status="item.status" /></td>
                                <td class="uk-text-nowrap">
                                    <button class="uk-button uk-button-default">Actions</button>
                                    <div uk-dropdown="mode: click">
                                        <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                            <li>
                                                <a 
                                                    v-if="item.status !== 4 && consist(user_cred.roles, ['qwe123', 'user-marketing', 'superadmin-tech', 'superadmin-product'])" 
                                                    class="uk-button" 
                                                    @click="showModal('close-silver-package', item)"
                                                >Close</a>
                                            </li>
                                            <li v-if="item.status !== 4 && consist(user_cred.roles, ['qwe123', 'user-marketing', 'superadmin-tech', 'superadmin-product'])" class="uk-nav-divider"></li>
                                            <li>
                                                <a 
                                                    v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'superadmin-tech', 'superadmin-product'])" 
                                                    class="uk-button" 
                                                    @click="showModal('reupload-silver-package', item)"
                                                >Reupload</a>
                                            </li>
                                            <li v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'superadmin-tech', 'superadmin-product'])" class="uk-nav-divider"></li>
                                            <li>
                                                <a
                                                    class="uk-button"
                                                    v-clipboard:copy="`${pwaUrl}${item.seo_url}`"
                                                    v-clipboard:success="onCopy"
                                                >Copy link</a>
                                            </li>
                                            <li class="uk-nav-divider"></li>
                                            <li>
                                                <router-link 
                                                    v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'superadmin-tech', 'superadmin-product'])" 
                                                    class="uk-button" 
                                                    :to="`/admin/marketing/silver_package/edit/${item._id}`"
                                                >Edit</router-link>
                                            </li>
                                            <li v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'superadmin-tech', 'superadmin-product'])" class="uk-nav-divider"></li>
                                            <li>
                                                <router-link :to="`/admin/marketing/silver_package/detail/${item._id}`" class="uk-button">Detail</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <empty-table v-else :colspan="10"/>
                    </template>
                </table>
            </div>
            <pagination
                v-if="silverPackageList !== null"
                :total-data="silverPackageList.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <!-- Close silver package modal box -->
        <div id="close-silver-package" class="uk-flex-top close-silver-package" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <h2 class="uk-modal-title uk-text-large uk-text-bold">Are you sure to close this job vacancy</h2>
                <div class="uk-text-right">
                    <button class="uk-button uk-button-default uk-border-rounded uk-modal-close" type="button">No</button>
                    <button 
                        class="uk-button uk-button-primary uk-border-rounded uk-margin-small-left" 
                        type="button"
                        :disabled="buttonLoading"
                        @click="closeSilverPackageJob"
                    >{{ buttonLoading ? 'Loading...' : 'Yes' }}</button>
                </div>
            </div>
        </div>

        <!-- Reupload silver package modal -->
        <div id="reupload-silver-package" class="uk-flex-top reupload-silver-package" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="salary_range.use = false"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Reupload Package</h2>
                </div>
                <div>
                    <form @submit.prevent="reuploadPackage">
                        <div class="uk-padding">
                            <p>Please setup start date and end date</p>
                            <div class="uk-flex uk-flex-between uk-flex-middle" style="gap: 20px;">
                                <section class="uk-width-1-2@s">
                                    <label for="job_start" class="uk-form-label">Start Date</label>
                                    <div>
                                        <div class="uk-inline uk-width-1-1">
                                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                            <datetime 
                                                input-id="job_start"
                                                name="Start Date"
                                                input-class="uk-input"
                                                input-style="border-radius: 5px;"
                                                type='date'
                                                placeholder="Select start date"
                                                value-zone="Asia/Jakarta"
                                                zone="Asia/Jakarta"
                                                :min-datetime="datetime.local().plus({days: 1}).toISODate()" 
                                                v-model="form.job_start" 
                                            ></datetime>
                                        </div>
                                    </div>
                                </section>
                                <section class="uk-width-1-2@s">
                                    <label for="job_end" class="uk-form-label">End Date</label>
                                    <div>
                                        <div class="uk-inline uk-width-1-1">
                                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                            <datetime 
                                                input-id="job_end"
                                                name="End Date"
                                                input-class="uk-input"
                                                input-style="border-radius: 5px;"
                                                type='date'
                                                placeholder="Select end date"
                                                :min-datetime="datetime.fromISO(form.job_start).plus({days: 1}).toISODate() && datetime.local().plus({days: 1}).toISODate() " 
                                                value-zone="Asia/Jakarta"
                                                zone="Asia/Jakarta"
                                                v-model="form.job_end"
                                                :disabled="!form.job_start"
                                            ></datetime>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>

                        <section class="uk-modal-footer uk-text-right">
                            <button 
                                type="submit"
                                class="uk-button uk-button-primary"
                                :disabled="!form.job_start || !form.job_end || form.job_start > form.job_end || buttonReuploadLoading"
                            >{{ buttonReuploadLoading ? 'Loading...' : 'Save' }}</button>
                        </section>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import { getUserLogin } from '@/utils/auth';
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import LabelStatus from '@/components/globals/LabelStatus';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import {
    notificationSuccess
} from '@/utils/notification';
import { DateTime } from 'luxon';
import { Datetime } from 'vue-datetime';

export default {
    name: 'MarketingSilverPackage',
    components: {
        Pagination,
        LabelStatus,
        EmptyTable,
        LoadingTable,
        Datetime
    },
    data() {
        return {
            meta: {
                limit: 50,
                page: 1,
                title: ''
            },
            isLoading: false,
            silverPackageList: null,
            selectedId: '',
            buttonLoading: false,
            buttonReuploadLoading: false,
            datetime: DateTime,
            form: {
                job_start: null,
                job_end: null
            }
        };
    },
    computed: {
        pwaUrl(){
            if (process.env.NODE_ENV === 'production') {
                return "https://app.myrobin.id/loker/";
            } else {
                return "https://app.myrobin.tech/loker/";
            }
        },
        user_cred() {
            return getUserLogin();
        }
    },
    watch: {
        meta() {
            this.getSilverPackage()
                .then((response) => {
                    this.silverPackageList = response;
                    this.autoCloseSilverPackageJob();
                });
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.close-silver-package')) window.UIkit.modal('.close-silver-package').$destroy(true);
        if (window.UIkit.modal('.reupload-silver-package')) window.UIkit.modal('.reupload-silver-package').$destroy(true);
    },
    async mounted() {
        this.isLoading = true;
        await this.getSilverPackage(this.meta)
            .then((response) => {
                this.silverPackageList = response;
                this.autoCloseSilverPackageJob();
            })
            .finally(() => {
                this.isLoading = false;
            });
    },
    methods: {
        ...mapActions({
            getSilverPackage: 'job/getSilverPackage',
            closeSilverPackage: 'job/closeSilverPackage',
            reuploadSilverPackage: 'job/reuploadSilverPackage'
        }),
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        salaryFormat(data) {
            if(data.salary !== null) {
                return formatter.salaryCurrency(data.salary);
            } else if(data.salary_min !== null && data.salary_max !== null) {
                return `${formatter.salaryCurrency(data.salary_min)} - ${formatter.salaryCurrency(data.salary_max)}`;
            }
        },
        dateFormat(data) {
            return formatter.dateComplete(data);
        },
        searchSilverPackage() {
            this.getSilverPackage(this.meta)
                .then((response) => {
                    this.silverPackageList = response;
                });
        },
        async showModal(modal_name, data = null) {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
            switch (modal_name) {
            case 'close-silver-package':
                if(data !== null) {
                    this.selectedId = data._id;
                }
                await window.UIkit.modal('#close-silver-package').show();
                break;
            case 'reupload-silver-package':
                if(data !== null) {
                    this.selectedId = data._id;
                    this.form.job_start = data.job_start;
                    this.form.job_end = data.job_end;
                }
                await window.UIkit.modal('#reupload-silver-package').show();
                break;
            }
        },
        async hideModal(modal_name) {
            switch (modal_name) {
            case 'close-silver-package':
                this.selectedId = '';
                await window.UIkit.modal('#close-silver-package').hide();
                break;
            case 'reupload-silver-package':
                this.selectedId = '';
                await window.UIkit.modal('#reupload-silver-package').hide();
                break;
            }
        },
        async closeSilverPackageJob() {
            this.buttonLoading = true;
            const response = await this.closeSilverPackage(this.selectedId);
            if(response.status === 'OK' && response.result === 'success') {
                this.hideModal('close-silver-package');
                notificationSuccess('Job vacancies closed successfully');
                this.buttonLoading = false;
                await this.getSilverPackage(this.meta)
                    .then((response) => {
                        this.silverPackageList = response;
                    });
            }
        },
        async reuploadPackage() {
            this.buttonReuploadLoading = true;
            const payload = {
                id: this.selectedId,
                job_start: this.form.job_start,
                job_end: this.form.job_end
            };
            
            const response = await this.reuploadSilverPackage(payload);
            if(response.status === 'OK' && response.result === 'success') {
                this.hideModal('reupload-silver-package');
                notificationSuccess('Job vacancies have been successfully re-uploaded');
                this.buttonReuploadLoading = false;
                await this.getSilverPackage(this.meta)
                    .then((response) => {
                        this.silverPackageList = response;
                    });
            }
        },
        onCopy(e) {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        autoCloseSilverPackageJob() {
            const now = formatter.datetimeComplete(new Date());
            this.silverPackageList.docs.forEach(async (job) => {
                const job_end = formatter.datetimeComplete(job.job_end);
                if(now > job_end) {
                    const response = await this.closeSilverPackage(job._id);
                    if(response.status === 'OK' && response.result === 'success') {
                        await this.getSilverPackage(this.meta)
                            .then((response) => {
                                this.silverPackageList = response;
                            });
                    }
                }
            });
        }
    }    
};
</script>
